<template>
    <div >   
            <v-dialog
                v-model="openDialog"
                max-width="500px" 
                @click:outside="closeDialog"
                
            > 
                <v-card class="mx-auto">
                    <v-card-title>
                    {{ $t('globals.response_options_to_question') }}:
                    </v-card-title>
                    <div></div>
                    <v-card-subtitle :style="{fontWeight:'bold'}">
                     "{{questionText}}"
                    </v-card-subtitle>
                    <v-card-text>
                        <v-container grid-list-md>
                        <div
                            v-for="(option, index) in possibleAnswers" :key="index"
                        >
                        <v-list-item two-line>
                        <v-list-item-content class="text-pointer">
                            <v-list-item-title :style="{'fontWeight':'bold', 'color':`${fontColor[option.valor]}`}" @click="setSelectedOption(option.valor)">{{index+1}}-{{option.texto}}:</v-list-item-title> 
                            <span :style="{  'wordBreak': 'break-word', 'color':fontColor[option.valor] }" @click="setSelectedOption(option.valor)">{{option.descripcion}}</span>
                        </v-list-item-content>
                        </v-list-item>
                        </div>
                            
                        </v-container>
                    </v-card-text>
                    
                </v-card>
            </v-dialog>
    
  </div>

</template>

<script>

  
    export default {

      name: "HelpDialog",

      props:{

        showDialog:Boolean,
        possibleAnswers:Array,
        questionText:String,
        questionId:Number,
        personId:Number,
        initOptionValue:Number
      },

      data: () => ({

          openDialog:false,
          fontColor:[] 
    
        }),

        mounted(){

            // console.log('entra amounted de HelpDialog!');
            
        },

        watch: {

        showDialog: {

                    handler: function (val) {
                        
                        if(val){
                            //  console.log(val);
                            this.openDialog=val;
                            this.resetFontColorArray();
                            if(this.initOptionValue>=0){
                                this.fontColor[this.initOptionValue]='blue';
                            }

                        }
                    
                    },
                    // deep: true,
                    // immediate:true

                }

        },

        computed:{

            

        },


    

      methods: {


        resetFontColorArray(){
            this.fontColor=[];
            this.possibleAnswers.forEach(element => {
                this.$set(this.fontColor, element.valor, 'black');
                
            });

        },

        setSelectedOption(optionID){

            // console.log(this.personId);
            this.resetFontColorArray();
            this.fontColor[optionID]='blue';
            this.$emit('option_selected', optionID,this.questionId,this.personId,);
            // this.openDialog=false;
            this.closeDialog();
            
            // console.log(this.fontColor);

        },
          
        closeDialog() {

            // this.setFontColorArray();
            this.fontColor=[];
            // console.log(this.fontColor);
            this.openDialog=false;
            this.$emit('close');

            }

        }
    }
</script>

<style scoped>
.popup {
  position: absolute;
  top: 30px;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.text-pointer {
  cursor: pointer;
}

</style>