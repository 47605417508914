<template>
    <div >

        <v-form ref="form" v-model="valid_form" lazy-validation>
            <v-dialog
                v-model="openDialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
               
                @click:outside="close"
            >

              
              <v-card class="mx-auto">
                <v-toolbar
                  dark
                  color="primary"
                >
                  <v-btn
                    icon
                    dark
                    @click="close"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>{{ $t('solar.close') }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  
                </v-toolbar>
                <v-card-title style="word-break: break-word">
                  <span class="headline">{{ modalTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container grid-list-md>

                    <v-col class="d-flex justify-space-around mb-6 align-end" cols="6">
                        <v-autocomplete 
                            v-model="selected_persons" 
                            :items="filteredEmployees" 
                            item-text="username" 
                            item-value="id"
                            :label="$t('active_source.persons')" 
                            persistent-hint 
                            small-chips 
                            multiple 
                            dense 
                            clearable
                            deletable-chips 
                            return-object
                            >
                           
                        </v-autocomplete>
                    </v-col>

                    <span v-if="responseType!='names_only'">
                     
                      <v-simple-table
                          fixed-header
                          height="800px"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">
                                  {{ $t('solar.person') }}
                                </th>
                                <th class="text-left">
                                  {{ $t('solar.reasons') }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(person) in selected_persons"
                                :key="person.id"
                              >
                                <td width="30%">{{ person.username }}</td>
                                 <td v-if="responseType=='names_with_text'">
                                  <v-textarea
                                    outlined
                                    :name="`reason-${person.id}`"
                                    label="describa sus razones..."
                                    v-model="reasons[`${person.id}`]"
                                    rows="2"
                                  ></v-textarea>
                                </td>
                              
                                <td v-if="responseType=='names_with_options'">
                                  <v-select 
                                        
                                        :id="`sel_${person.id}`"
                                        v-model="response_options[person.id]"
                                        :items="options"
                                        item-text="texto" 
                                        item-value="valor" 
                                        clearable
                                        :multiple="solarQuestion.question_possible_answers.multiple" 
                                        deletable-chips 
                                        small-chips 
                                        outlined 
                                        flat
                                        rounded 
                                        class="my-5" 
                                        dense
                                        @change="optionIsSelected($event,person.id,'Otro')"
                                      >
                                  </v-select>

                                  <v-textarea v-if="showTextArea[person.id]"
                                    :name="`reason-${person.id}`"
                                    label="describa sus razones..."
                                    v-model="reasons[person.id]"
                                    rows="2"
                                  >


                                  </v-textarea>
                                </td>
                            </tr>
                            </tbody>
                          </template>
                        </v-simple-table>

                    </span>
                   
                    

                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                    
                    <v-btn color="blue"   dark  @click="close">{{ $t('globals.cancel') }}</v-btn>
                    <v-btn color="success"   @click.native="saveSelectedPersons" >{{ $t('globals.save') }}</v-btn>
                    
                </v-card-actions>
              </v-card>
            </v-dialog>
        </v-form>

  </div>

</template>

<script>
import { useMainStore } from '@/store/main';
import { mapStores} from 'pinia';
  
    export default {

      name: "SelectPersonsDialog",

      props:[
            'modalTitle',
            'showDialog',
            'questionID',
            'responseType',
            'defaultOptions',
            'maxSelections',
            'solarQuestion'
            
        ],

      data: () => ({

            openDialog:false,
            valid_form:false ,
            selected_persons:[],
            selected_area:'',
            reasons:{},
            response_options:[],
            showTextArea:[]

        }),


      mounted(){

        // console.log('entra amounted!')

      },

      watch: {

        showDialog: {

            handler: function (val) {

              // console.log(val);

                this.openDialog=val

            },
            // deep: true,
            // immediate:true

        },


        defaultOptions: {

            handler: function (val) {


              if(val !== undefined){
               
                if(this.responseType==='names_with_text'){

                    for (let key in val) {

                      this.selected_persons.push(this.filteredEmployees.find(person => person.id==key));
                      this.reasons[key]=val[key];
                                      
                    }

                }
                if(this.responseType==='names_with_options'){

                  for (let key in val) {

                    this.selected_persons.push(this.filteredEmployees.find(person => person.id==key));
                    this.response_options[key]=val[key].options;
                    this.setTextAreaContent(this.response_options[key], key,'Otro',val[key].text);
                                    
                  }


                }
                else if(this.responseType==='names_only'){
                  val.forEach(id =>{

                    this.selected_persons.push(this.filteredEmployees.find(person => person.id==id))

                  })

                }

              }

              // this.selected_persons = val;

            },
              // deep: true,
              // immediate:true

        },


        selected_persons: {

          handler: function (val) {

           
            if (this.maxSelections!=undefined && val.length > this.maxSelections){
              alert('Máximo '+ this.maxSelections +' nombres seleccionados permitidos!!');
              this.selected_persons.pop();            }

          },
          //  deep: true,
          //  immediate:true

        },

        },

      computed: {

            // ...mapState(useMainStore, ["selected_cycle"]),
            ...mapStores(useMainStore),


            filteredEmployees() {


                // if (this.selected_area) {

                return this.mainStore.employees.filter(item => item.id_organization_area === this.mainStore.logged_user.area_id);

                // }
                // else return this.mainStore.employees;

            },

            options(){

              return JSON.parse(this.solarQuestion.question_possible_answers[`Question_possible_answers_${this.$i18n.locale}`]);

            },
           
            
        },


      methods: {


        optionIsSelected(evento, personID,option_name){

         let opt_obj= this.optionExists(option_name);

         if(opt_obj && evento.includes(opt_obj.valor)){
            this.showTextArea[personID]=true;

           }
    
          else  this.showTextArea[personID]=false;    

         
        },


        setTextAreaContent(evento, personID,option_name, content){

          let opt_obj= this.optionExists(option_name);

          if(opt_obj && evento.includes(opt_obj.valor)){
            this.showTextArea[personID]=true;
            this.reasons[personID]=content;

            }

          else  this.showTextArea[personID]=false;    


          },


          optionExists(option_name){

            return this.options.find(option => option.texto===option_name);

          },

          
          
          close() {

              this.openDialog = false;
              this.selected_persons=[];
              this.$emit('close');

            },

          clear() {
            this.$refs.form.reset();
            this.selected_persons=[];
           },

          saveSelectedPersons(){

            let  data ={};

            if(this.responseType=='names_with_text'){

              this.selected_persons.forEach(person=>{
                if(!Object.getOwnPropertyDescriptor(this.reasons, person.id)){
                  this.reasons[person.id]=" ";
                }

              });

              data ={
                'selected_persons':this.reasons,
                'questionID':this.questionID
              }
            }
            if(this.responseType=='names_with_options'){

              let selected_persons_obj={};

              this.selected_persons.forEach(person=>{
                
                selected_persons_obj[person.id]={options:this.response_options[person.id]||[], text:''};

              });


              // let selected_persons_obj=Object.assign({},this.response_options);

              console.log(this.response_options);

              // console.log(selected_persons_obj);
              // console.log(this.reasons);

              if(Object.keys(this.reasons).length){

                  Object.entries(this.reasons).forEach(entry => {
                    const [key, value] = entry;

                    selected_persons_obj[key].text=value;
                
                  });

              }

              data ={
                'selected_persons':selected_persons_obj,
                'questionID':this.questionID
              }
            }
            else if(this.responseType=='names_only') {

              data ={
                'selected_persons':this.selected_persons.map(item => item.id),
                'questionID':this.questionID
              }

            }
            
            this.$emit('personsSelected', data);
            this.close();


           }

        }
    }
</script>

